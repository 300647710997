<template>
  <div>
      <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <svg class="w-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" fill="#5B5B5B">
              <path d="M0 3L0 17L24 17L24 3L0 3 z M 5.125 4.5L18.875 4.5L19.765625 5.2675781 A 1.2819999 1 44.999 0 0 20.191406 6.4316406 A 1.2819999 1 44.999 0 0 21.546875 6.8046875L22.5 7.625L22.5 12.375L21.546875 13.197266 A 1 1.2819999 44.999 0 0 20.193359 13.568359 A 1 1.2819999 44.999 0 0 19.765625 14.732422L18.875 15.5L5.125 15.5L4.2324219 14.730469 A 1.2819999 1 44.999 0 0 3.8066406 13.568359 A 1.2819999 1 44.999 0 0 2.453125 13.195312L1.5 12.375L1.5 7.625L2.453125 6.8027344 A 1 1.2819999 44.999 0 0 3.8066406 6.4316406 A 1 1.2819999 44.999 0 0 4.234375 5.2675781L5.125 4.5 z M 12 6.625 A 3.625 3.375 0 0 0 8.375 10 A 3.625 3.375 0 0 0 12 13.375 A 3.625 3.375 0 0 0 15.625 10 A 3.625 3.375 0 0 0 12 6.625 z M 5.5 8.625 A 1.5 1.375 0 0 0 4 10 A 1.5 1.375 0 0 0 5.5 11.375 A 1.5 1.375 0 0 0 7 10 A 1.5 1.375 0 0 0 5.5 8.625 z M 18.5 8.625 A 1.5 1.375 0 0 0 17 10 A 1.5 1.375 0 0 0 18.5 11.375 A 1.5 1.375 0 0 0 20 10 A 1.5 1.375 0 0 0 18.5 8.625 z M 0 18L0 20L0.75 20C9.8986486 20 21.126953 21.865234 21.126953 21.865234L22 22.009766L22 18L21.5 18L20.5 18L20.5 18.009766L18.96875 18.009766C18.96075 18.017766 18.950359 18.020344 18.943359 18.027344C18.572332 18.397631 18.428595 18.885264 18.537109 19.271484L17.634766 19.849609C14.015693 19.356382 7.6632741 18.615729 1.5 18.550781L1.5 18L0 18 z" fill="#5B5B5B" />
            </svg>
            <h3 class="pl-2 text-uppercase text-lg">Due and Collection</h3>
          </div>
        </div>
      </template>
      <CardBody class="p-3 relative">
        <div class="mb-4 flex justify-end flex-wrap justify-xs-center">
          <SearchBox
            placeholder="Search"
            class="w-74 p-2 m-1"
            :results="searchResults"
            v-model="keyword"
            result-key="title"
            @on-result="onResult"
            />
        </div>
        <md-divider></md-divider>
        <Table :items="assignToTrainerOrAdmin" hover-action>
          <template slot="row" slot-scope="{ item }">
            <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-sort-by="testId" md-label="TEST_ID">{{ item.testId }}</md-table-cell>
            <md-table-cell md-sort-by="testTitle" md-label="TEST TITLE">{{ item.testTitle }}</md-table-cell>
            <md-table-cell md-sort-by="date" md-label="DATE">{{ item.date }}</md-table-cell>
            <md-table-cell md-sort-by="testName" md-label="TEST NAME">{{ item.testName }}</md-table-cell>
            <md-table-cell md-sort-by="assignTo" md-label="ASSIGN TO" class="text-victoria">{{ item.assignTo }}</md-table-cell>
            <md-table-cell md-sort-by="createdAt" md-label="CREATED AT">{{ item.createdAt }}</md-table-cell>
            <md-table-cell md-sort-by="status" md-label="STATUS" class="text-green">{{ item.status }}</md-table-cell>
            <span class="action">
              <md-icon @click.native="onShowDetails(item)" class="bg-victoria rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
              <md-icon class="bg-default rounded-full p-1 text-gray-700 text-base">delete</md-icon>
            </span>
          </template>
        </Table>

        <div @click="onShowCreate" style="position: absolute; bottom: 0; right: 0"
          class="bg-victoria pointer center w-24 h-24 rounded-full">
          <md-icon class="text-6xl text-white">add</md-icon>
        </div>
      </CardBody>
    </Card>
    <div class="flex justify-end align-center mt-8">
          <Paginate
            :start="paginate.start"
            :end="paginate.end"
            :total="paginate.total"
            :limit="paginate.limit"
            @on-start="onStart"
            @on-end="onEnd"
          />
      </div>
    <Dialog>
      <component :title="title" :content="content" :is="component"></component>
    </Dialog>
  </div>
</template>

<script>
import {
    Card,
    Table,
    Dialog,
    CardBody,
    Paginate,
    SearchBox
    } from "@/components";
import { DetailsModal, TMTrainerOrAdminCreateModal, PromptEdit } from "@/components/molecule";
import DetailsDialog from "@/components/molecule/task/DetailsDialog";
import cms from "@/data/cms";
import { assignToTrainerOrAdmin } from '@/data/master/qatm/test-management';
import { mapMutations } from "vuex";
import { paginate } from "@/mixins/paginate";
export default {
    mixins: [paginate],
    components: {
        Card,
        Table,
        Dialog,
        Paginate,
        CardBody,
        SearchBox,
        PromptEdit,
        DetailsModal,
        DetailsDialog,
        TMTrainerOrAdminCreateModal
  },
  data() {
      return {
          currentIndex: 0,
          component: 'CreateModal',
          types: cms.types,
          keyword: '',
          title: "",
          content: "",
          selectedKeyword: '',
          query: '?',
          visibility: true,
          pagination: true,
          selectedValue: null,
          itemIndex: null,
          status: '',
          searchParams : new URLSearchParams(),
          paginate: {
            start: 1,
            end: 20,
            total: 100,
            limit: 20
          }
      }
  },
  computed: {
    assignToTrainerOrAdmin() {
      if(this.pagination) {
        return assignToTrainerOrAdmin.slice(this.paginate.start, this.paginate.end);
      }
      return assignToTrainerOrAdmin;
    },
    searchResults() {
        if(this.keyword !== '' && this.keyword !== this.selectedKeyword) {
          return assignToTrainerOrAdmin.filter(item => this.toLower(item.title).includes(this.toLower(this.keyword)))
        }
        return [];
      }
  },
   methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onShowDetails(item) {
      this.component = 'DetailsDialog';
      this.title = 'Details',
      this.content = item;
      this.dialog(true);
    },
    onShowCreate() {
      this.component = 'TMTrainerOrAdminCreateModal';
      this.dialog(true);
    },
    onShowEdit() {
      this.component = 'PromptEdit';
      this.dialog(true);
    },
    onResult(value) {
      this.keyword = this.selectedKeyword = value;
      this.onPrepareQueryString('search', this.keyword);
    },
    onStart(value) {
      this.paginate.start -= value;
      this.paginate.end -= value;
      this.onPrepareQueryString('offset', this.paginate.start);
      this.onPrepareQueryString('limit', this.paginate.end);
    },
    onEnd(value) {
      this.paginate.start += value;
      this.paginate.end += value;
      this.onPrepareQueryString('offset', this.paginate.start);
      this.onPrepareQueryString('limit', this.paginate.end);
    },
    onPrepareQueryString(key, value) {
      if(this.searchParams.has(key)) {
          this.searchParams.delete(key);
      }
      this.searchParams.append(key, value);
      console.log(this.searchParams.toString());
    },
    toLower(text){
      return text.toString().toLowerCase()
    },
  },
}
</script>